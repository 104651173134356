
// 控制菜单展开或收起
export default{
    state:{
        is_destroy:false
    },
    mutations:{
        SET_IS_DESTROY(state,val){
            state.is_destroy = val
        }
       
    }
}