export const constantRoutes = [
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/login/index'),
        hidden: true,
    }, {
        path: '/',
        component: () => import('@/layout'),
        redirect: '',
    },
]

