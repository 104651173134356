//引入VueRouter
import VueRouter from 'vue-router'
// import Cookies from 'js-cookie'
import store from '@/store'
//引入静态路由
import { constantRoutes } from './constantRoutes'

//创建router实例对象，去管理一组一组的路由规则
const router = new VueRouter({

    routes: constantRoutes
})


//路由守卫:在跳转之前验证token
router.beforeEach((to, from, next) => {
    const token = store.state.user.token
    if (to.name == 'syncAttendance' || to.name == 'syncViewPermits' ||to.name == 'syncHomeworkPublish' ||to.name == 'syncHomeworkUpdate' ) {
        next()
    }
    else {
        // const token = Cookies.get('ELLEN-ADMIN') 
        if (!token && to.name !== 'login') {
            next({ name: 'login' })
        } else if (token && to.name === 'login') {
            next({ name: 'Analysis' })
        }
        else {
            next()
        }
    }

});

// reset router
export const resetRouter = function () {
    const newRouter = new VueRouter({
        routes: constantRoutes
    })
    router.matcher = newRouter.matcher
}

export default router

