
// 面包屑
export default {
    state: {
        List: [
            {
                path: '/educational/classroom',
                name: 'classroom',
                meta: { title: '班级列表', icon: 'setting', role: ['admin', 'manager', 'teacher', 'custom'] },
            }
        ]
    },
    mutations: {
        setTags(state, item) {
            const index = state.List.findIndex(i => i.name === item.name)
            if (index === -1) {
                state.List.push(item)
            }
        },
        deleteTags(state, tl) {
            //console.log(state.List)
            const index = state.List.findIndex(i => i.name === tl.name)
            state.List.splice(index, 1)
        },
        resetTags(state) {
            state.List = [{
                path: '/educational/classroom',
                name: 'classroom',
                meta: { title: '班级列表', icon: 'setting', role: ['admin', 'manager', 'teacher', 'custom'] },
            }]
        }
    },

}