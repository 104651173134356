
export default {
    getMenu: config => {
        const { username, password } = JSON.parse(config.body)
        if (username === 'admin' && password == 'admin') {
            return {
                code: 2000,
                data: {
                    token: 'admin-token',
                    roles: ['admin'],
                    introduction: 'I am an administrator',
                    avatarUrl: 'https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif',
                    userName: 'Admin'
                }
            }
        }
        else if (username === 'xiaoxiao' && password == 'xiaoxiao') {
            return {
                code: 2000,
                data: {
                    token: 'Editor-token',
                    roles: ['editor'],
                    introduction: 'I am an editor',
                    avatarUrl: 'https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif',
                    userName: 'Editor'
                }
            }
        }
        else {
            return {
                code: -999,
                data: {
                    message: '密码错误'
                }
            }
        }
    }
}