import Vue from 'vue'
import Vuex from 'vuex'
import CreatePersistedState from 'vuex-persistedstate'  //vuex持久化
import MenuFold from './MenuFold'
import tags from './tags'
import user from './user'
import ip from './ip'
import classroomMange from './classroomMange'

Vue.use(Vuex)
//模块化管理Vuex各配置
export default new Vuex.Store({
    modules: {
        MenuFold: MenuFold,
        tags: tags,
        user: user,
        ip,
        classroomMange: classroomMange

    },

    plugins: [
        // veux持久化配置
        CreatePersistedState({
            storage: window.sessionStorage,
            key: 'vue-x-p',
            paths: ['tags', 'user']
        })

    ]
})

