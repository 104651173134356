
// 控制菜单展开或收起
export default{
    state:{
        Fold:false
    },
    mutations:{
        MenuFold(state){
            state.Fold = !state.Fold
        }
       
    }
}