import Vue from 'vue'
import App from './App.vue'

//全局引入UI
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

// //按需引入UI
// import { Button, Row } from 'element-ui';
// Vue.use(Button)
// Vue.use(Row)

//引入路由
import VueRouter from 'vue-router'
import router from './router'
//应用路由
Vue.use(VueRouter)

//路由重复跳转报错
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}
//vuex
import store from './store';
//Mock拦截
import './mock/index';
//echarts
import 'echarts';
import Echarts from 'vue-echarts';
Vue.component('echart',Echarts)

import './style/public.css'
import './style/ppp.less'
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router:router,
  store:store,
  created(){
    document.title = '学生信息管理系统'; // 设置网页标题
    store.commit('SET_MENU') //页面刷新重新加载路由，根据持久化vuex user role 以及 router/asyncRoutes,调用SET_MENU重新计算路由
  }
  
}).$mount('#app')
