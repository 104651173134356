import Mock from "mockjs";
import permission from "./permission";
Mock.mock('/api/user/getUser','post',permission.getMenu)

// Mock.mock('/api/home/getHome',function(){
//     return {
//         zzt:[
//             {
//                 yue:'1月',
//                 value:200
//             },
//             {
//                 yue:'2月',
//                 value:300
//             },
//             {
//                 yue:'3月',
//                 value:500
//             },
//             {
//                 yue:'4月',
//                 value:200
//             },
//             {
//                 yue:'5月',
//                 value:200
//             },
//             {
//                 yue:'6月',
//                 value:200
//             }

//         ],
//         zxt:[
//             {
//                 yue:'法规',
//                 value:200
//             },
//             {
//                 yue:'管理',
//                 value:300
//             },
//             {
//                 yue:'经济',
//                 value:500
//             },
//             {
//                 yue:'建筑',
//                 value:200
//             },
//             {
//                 yue:'铁路',
//                 value:200
//             }
//         ],
//     }
// })