export const asyncRoutes = [
    {
        path: '/dashboard',
        component: () => import('@/layout'),
        redirect: '/dashboard/workbench',
        meta: { title: 'Dashboard', icon: 's-home', role: ['admin', 'editor'] },
        children: [
            // {
            //     path: '/dashboard/analysis',
            //     name: 'Analysis',
            //     component: () => import('@/views/dashboard/analysis'),
            //     hidden: true,
            //     meta: { title: '分析页', icon: 'setting', role: ['admin', 'editor'] }
            // },
            {
                path: '/dashboard/workbench',
                name: 'Workbench',
                component: () => import('@/views/dashboard/workbench'),
                // hidden: true,
                meta: { title: '工作台', icon: 'setting', role: ['admin', 'editor'] }
            }
        ]
    }, {
        path: '/educational',
        component: () => import('@/layout'),
        redirect: '/educational/classroom',
        hidden: true,
        meta: { title: '教务管理', icon: 'setting', role: ['admin', 'manager', 'teacher', 'custom'] },
        children: [
            {
                path: '/educational/classroom',
                name: 'classroom',
                component: () => import('@/views/educational/classroom'),
                hidden: true,
                meta: { title: '班级列表', icon: 'setting', role: ['admin', 'manager', 'teacher', 'custom'] },
            }, {
                path: '/educational/company',
                name: 'company',
                component: () => import('@/views/educational/company'),
                hidden: true,
                meta: { title: '公司信息', icon: 'setting', role: ['admin', 'manager', 'teacher'] },
            }, {
                path: '/educational/subject',
                name: 'subject',
                component: () => import('@/views/educational/subject'),
                hidden: true,
                meta: { title: '科目信息', icon: 'setting', role: ['admin', 'manager', 'teacher'] },
            }, {
                path: '/educational/tool',
                name: 'tool',
                component: () => import('@/views/educational/tool'),
                hidden: true,
                meta: { title: '常用功能', icon: 'setting', role: ['admin', 'manager', 'teacher'] },
            },
            {
                path: '/educational/classroom/classRoomMange',
                name: 'classRoomMange',
                component: () => import('@/views/educational/classroom/classRoomMange'),
                meta: { title: '班级管理', icon: 'setting', role: ['admin', 'manager', 'teacher', 'custom'], keepAlive: true },
            },
        ]
    },
    {
        path: '/educational/classroom/classRoomMange/studentInfo',
        name: 'studentInfo',

        component: () => import('@/views/educational/classroom/classRoomMange/student/components/studentInfo'),
        meta: { title: '学员信息', icon: 'setting', role: ['admin', 'manager', 'teacher', 'custom'] },
    },
    {
        path: '/user',
        component: () => import('@/layout'),
        redirect: '/user/account',
        hidden: true,
        meta: { title: '用户管理', icon: 'setting', role: ['admin', 'manager'] },
        children: [
            {
                path: '/user/staff',
                name: 'staff',
                component: () => import('@/views/user/staff'),
                hidden: true,
                meta: { title: '员工管理', icon: 'setting', role: ['admin', 'manager'] }
            },
            {
                path: '/user/studentInfo',
                name: 'studentInfoAll',
                component: () => import('@/views/user/studentInfo'),
                hidden: true,
                meta: { title: '学员信息', icon: 'setting', role: ['admin'] }
            },
            {
                path: '/user/dept',
                name: 'dept',
                component: () => import('@/views/user/dept'),
                hidden: true,
                meta: { title: '部门管理', icon: 'setting', role: ['admin', 'manager'] }
            }
        ]
    },
    {
        path: '/system',
        component: () => import('@/layout'),
        redirect: '/system/help',
        hidden: true,
        meta: { title: '系统管理', icon: 'setting', role: ['admin', 'manager'] },
        children: [
            {
                path: '/system/help',
                name: 'help',
                hidden: true,
                component: () => import('@/views/system/help'),
                meta: { title: '使用说明', icon: 'setting', role: ['admin', 'manager', 'teacher'] },
            },
        ]
    },

]
