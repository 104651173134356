<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  mounted() {},
};
</script>

<style>
html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
#app {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
.el-card__body {
  padding: 0px;
}
.el-menu-item-group__title {
  padding: 0px;
}
.el-breadcrumb__inner {
  margin: 0 !important;
  color: #3f3e3e !important;
  font-weight: 400 !important;
}
.el-breadcrumb__separator {
  margin: 8px;
  color: #3f3e3e !important;
  font-weight: 400 !important;
}
</style>
