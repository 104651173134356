
// 用户
import { asyncRoutes } from '@/router/asyncRoutes'
import router from '@/router';

//
function matchArray(arr1, arr2) {
    return arr2.some(item => arr1.includes(item));
}

export default {
    state: {
        id: '',
        token: '',
        roles: '',
        introduction: '',
        avatarUrl: '',
        userName: '',
        menu: '',
        isShow: '',
    },
    mutations: {
        //修改vuex 用户信息
        SET_USER(state, data) {
            state.id = data.id
            state.token = data.token
            state.roles = data.roles
            state.introduction = data.introduction
            state.avatarUrl = data.avatarUrl
            state.userName = data.userName
            if (data.roles[0] == 'custom') {
                state.isShow = false
            } else {
                state.isShow = true
            }
        },

        //根据用户角色，计算用户路由
        SET_MENU(state) {
            const all = asyncRoutes
            const arr = state.roles

            const aa = all.filter((item) => {
                if (matchArray(item.meta.role, arr)) {
                    if (item.children) {
                        item.children = item.children.filter((cl) => matchArray(cl.meta.role, arr))
                    }
                    return matchArray(item.meta.role, arr)
                }
                else {
                    return false
                }

            });
            aa.forEach((route) => {
                router.addRoute(route)		//动态添加路由
            })
            state.menu = aa
        },
        DELET_EUSER(state) {
            state.id = ''
            state.token = ''
            state.roles = ''
            state.introduction = ''
            state.avatarUrl = ''
            state.userName = ''
            state.isShow = ''
        },

    },
}